import React from 'react';
import PageWrap from '../PageWrap/PageWrap';
import Header from '../Header/Header';
import './Gallery.css';

class Gallery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showFullImages: false,
			currInd: 0,
			length: 0,
		};
	}

	importAll = (r) => {
		return r.keys().map(r);
	};

	getImageIndex = (imageArray, string) => {
		return imageArray.findIndex((element) => element.includes(string));
	};

	openImage = (e, ind, length) => {
		this.setState({
			showFullImages: true,
			currInd: ind,
			length,
		});
	};

	closeImage = () => {
		this.setState({
			showFullImages: false,
		});
	};

	galleryNav = (e) => {
		e.stopPropagation();
		if (e.target.name === 'prev') {
			this.setState({ currInd: this.state.currInd - 1 });
		}
		if (e.target.name === 'next') {
			this.setState({ currInd: this.state.currInd + 1 });
		}
	};

	render() {
		const images = this.importAll(
			require.context('./images', false, /\.(png|jpe?g|svg)$/)
		);
		const imagesLength = images.length;
		return (
			<div className='gallery-container page-content'>
				<PageWrap pageWrapClasses={'gallery'}>
					<Header page='gallery' />
					<div className='gallery-images-container'>
						{images.map((image, ind) => (
							<div
								className='gallery-image'
								onClick={(e) => {
									this.openImage(e, ind, imagesLength);
								}}
								key={ind}
							>
								<img src={image} alt='gallery' />
							</div>
						))}
						{this.state.showFullImages ? (
							<div
								className='gallery-full-image-overlay'
								onClick={this.closeImage}
							>
								{this.state.currInd > 0 ? (
									<button
										className='gallery-nav-button prev'
										onClick={this.galleryNav}
										name='prev'
									></button>
								) : null}

								<div className='gallery-full-image'>
									<img
										src={images[this.state.currInd]}
										alt='gallery full'
									/>
								</div>
								{this.state.currInd < this.state.length - 1 ? (
									<button
										className='gallery-nav-button next'
										onClick={this.galleryNav}
										name='next'
									></button>
								) : null}
							</div>
						) : null}
					</div>
				</PageWrap>
			</div>
		);
	}
}

export default Gallery;
