import React from 'react';
import PageWrap from '../PageWrap/PageWrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div className='footer-container'>
				<PageWrap pageWrapClasses={'footer'}>
					<div className='footer-column socials'>
						<ul className='footer-social-nav'>
							<li className='social-nav-link instagram'>
								<a
									href='https://instagram.com/vinoraha.photo'
									target='_blank'
									rel='noopener noreferrer'
								>
									<FontAwesomeIcon
										icon={['fab', 'instagram']}
										className='social-nav-icon'
									/>
								</a>
							</li>
							<li className='social-nav-link facebook'>
								<a
									href='https://facebook.com/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<FontAwesomeIcon
										icon={['fab', 'facebook']}
										className='social-nav-icon'
									/>
								</a>
							</li>
							<li className='social-nav-link twitter'>
								<a
									href='https://twitter.com/'
									target='_blank'
									rel='noopener noreferrer'
								>
									<FontAwesomeIcon
										icon={['fab', 'twitter']}
										className='social-nav-icon'
									/>
								</a>
							</li>
						</ul>
					</div>
					<div className='footer-column contact'>
						<ul className='footer-contact-nav'>
							<li className='contact-nav-link'>
								<a href='mailto:info@vinorahaphotography.com'>
									email: info@vinorahaphotograpy.com
								</a>
							</li>
							<li className='contact-nav-link'>
								<a href='tel:4168942606'>
									phone: (416) 894-2606
								</a>
							</li>
							<li className='contact-nav-link'>
								<p>
									Created by{' '}
									<a
										href='https://christopherarsenault.com'
										target='_blank'
									>
										Christopher Arsenault
									</a>{' '}
									-{' '}
									<a
										href='https://www.twitter.com/clarsena'
										target='_blank'
									>
										clarsena Codes
									</a>
								</p>
							</li>
						</ul>
					</div>
				</PageWrap>
			</div>
		);
	}
}

export default Footer;
