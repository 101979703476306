import React from 'react';
import './Header.css';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		let content;

		if (this.props.page === 'pricing') {
			content = (
				<h2 className='content-page-title'>PACKAGES / PRICING</h2>
			);
		}

		if (this.props.page === 'gallery') {
			content = <h2 className='content-page-title'>GALLERY</h2>;
		}

		if (this.props.page === 'about-us') {
			content = <h2 className='content-page-title'>ABOUT</h2>;
		}

		if (this.props.page === 'contact') {
			content = <h2 className='content-page-title'>CONTACT</h2>;
		}

		if (this.props.page === 'contact-success') {
			content = <h2 className='content-page-title'>CONTACT</h2>;
		}

		return <div>{content}</div>;
	}
}

export default Header;
