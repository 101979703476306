import React from 'react';
import PageWrap from '../PageWrap/PageWrap';
import Header from '../Header/Header';
import './Contact.css';

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			labels: {
				firstName: false,
				lastName: false,
				phone: false,
				email: false,
				message: false,
			},
			inputs: {
				firstName: '',
				lastName: '',
				phone: '',
				email: '',
				message: '',
			},
		};
	}

	handleChange = (e) => {
		const target = e.target;
		this.setState((prevState) => {
			return {
				...prevState,
				labels: {
					...prevState.labels,
					[target.name]: target.value ? true : false,
				},
				inputs: {
					...prevState.inputs,
					[target.name]: target.value,
				},
			};
		});
	};
	handleSubmit = () => {};

	render() {
		return (
			<div className='contact-container page-content'>
				<PageWrap pageWrapClasses={'contact'}>
					<Header page='contact' />
					<form
						className='contact-form-container'
						method='POST'
						action='http://www.focuspocus.io/magic/9c6b301ce7eb2a01e975f702d3b76b8c'
					>
						<div className='contact-form-row'>
							<div className='contact-input-container half'>
								<label
									htmlFor='firstName'
									className={`contact-label ${
										this.state.labels.firstName
											? 'show'
											: 'hide'
									}`}
								>
									{this.state.labels.firstName
										? 'FIRST NAME*'
										: ''}
								</label>
								<input
									type='text'
									name='firstName'
									id='firstName'
									placeholder={
										this.state.labels.firstName
											? ''
											: 'FIRST NAME*'
									}
									value={this.state.inputs.firstName}
									onChange={this.handleChange}
									className='contact-input'
									required={true}
									autoComplete='off'
								/>
							</div>
							<div className='contact-input-container half'>
								<label
									htmlFor='lastName'
									className={`contact-label ${
										this.state.labels.lastName
											? 'show'
											: 'hide'
									}`}
								>
									{this.state.labels.lastName
										? 'LAST NAME*'
										: ''}
								</label>
								<input
									type='text'
									name='lastName'
									id='lastName'
									placeholder={
										this.state.labels.lastName
											? ''
											: 'LAST NAME*'
									}
									value={this.state.inputs.lastName}
									onChange={this.handleChange}
									className='contact-input'
									required={true}
									autoComplete='off'
								/>
							</div>
						</div>

						<div className='contact-form-row'>
							<div className='contact-input-container half'>
								<label
									htmlFor='email'
									className={`contact-label ${
										this.state.labels.email
											? 'show'
											: 'hide'
									}`}
								>
									{this.state.labels.email ? 'EMAIL*' : ''}
								</label>
								<input
									type='email'
									name='email'
									id='email'
									placeholder={
										this.state.labels.email ? '' : 'EMAIL*'
									}
									value={this.state.inputs.email}
									onChange={this.handleChange}
									className='contact-input'
									required={true}
									autoComplete='off'
								/>
							</div>
							<div className='contact-input-container half'>
								<label
									htmlFor='phone'
									className={`contact-label ${
										this.state.labels.phone
											? 'show'
											: 'hide'
									}`}
								>
									{this.state.labels.phone ? 'PHONE*' : ''}
								</label>
								<input
									type='phone'
									name='phone'
									id='phone'
									placeholder={
										this.state.labels.phone ? '' : 'PHONE*'
									}
									value={this.state.inputs.phone}
									onChange={this.handleChange}
									className='contact-input'
									required={true}
									autoComplete='off'
								/>
							</div>
						</div>

						<div className='contact-form-row'>
							<div className='contact-input-container full'>
								<label
									htmlFor='message'
									className={`contact-label ${
										this.state.labels.message
											? 'show'
											: 'hide'
									}`}
								>
									{this.state.labels.message
										? 'MESSAGE*'
										: ''}
								</label>
								<textarea
									name='message'
									id='message'
									rows='10'
									placeholder={
										this.state.labels.message
											? ''
											: 'PLEASE TELL ME A BIT ABOUT YOURSELF AND WHAT YOU ARE LOOKING TO HAVE DONE*'
									}
									value={this.state.inputs.message}
									onChange={this.handleChange}
									className='contact-input'
									required={true}
									autoComplete='off'
								/>
							</div>
						</div>

						<div className='contact-form-row'>
							<input
								type='submit'
								className='contact-submit'
								value='SEND'
							/>
						</div>
					</form>
				</PageWrap>
			</div>
		);
	}
}

export default Contact;
