import React from 'react';
import PageWrap from '../PageWrap/PageWrap';
import Header from '../Header/Header';
import ProfilePicture from './profile_picture.jpg';
import './About.css';

class About extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div className='about-us-container page-content'>
				<PageWrap pageWrapClasses={'about-us'}>
					<Header page='about-us' />
					<div className='profile-container'>
						<div className='profile-column profile-picture'>
							<img src={ProfilePicture} alt='profile' />
						</div>
						<div className='profile-column profile-text'>
							<p>
								Hello, my name is Vino, a family / lifestyle
								photograher based out of Shakespeare, ON
								(serving the Stratford & K/W/C areas).
							</p>
							<p>
								My love of photography began with capturing the
								beauty of different cuisines and has since
								evolved into capturing the beauty of the people
								that surround us.
							</p>
							<p>
								I believe in capturing moments as they happen. I
								love capturing new memories of fresh, little
								ones, as well as connections in relationships
								and families.
							</p>
							<p>
								Life is too short to be anything but happy. Love
								deeply, forgive quickly, and let's take a chance
								together.
							</p>
							<p>I look forward to meeting you!!</p>
							<p>Love,</p>
							<p>Vino</p>
						</div>
					</div>
				</PageWrap>
			</div>
		);
	}
}

export default About;
