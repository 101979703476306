import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import LogoBig from './website_logo_white.png';
import PageWrap from '../PageWrap/PageWrap';
import './TopNav.css';

class TopNav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileMenu: false,
		};
	}

	toggleMenu = () => {
		this.setState((prevState) => {
			return {
				mobileMenu: !prevState.mobileMenu,
			};
		});
	};

	render() {
		return (
			<div className='top-nav-container'>
				<PageWrap pageWrapClasses={'top-nav-desktop'}>
					<div className='top-nav-column'>
						<ul className='top-nav-list'>
							<li>
								<Link to='/about' className='top-nav-link'>
									About
								</Link>
							</li>
							<li>
								<Link to='/gallery' className='top-nav-link'>
									Gallery
								</Link>
							</li>
						</ul>
					</div>
					<Link to='/' className='top-nav-link'>
						<div className='top-nav-logo'>
							<img src={LogoBig} alt='Site logo' />
						</div>
					</Link>
					<div className='top-nav-column'>
						<ul className='top-nav-list'>
							<li>
								<Link to='/pricing' className='top-nav-link'>
									Pricing
								</Link>
							</li>
							<li>
								<Link to='/contact' className='top-nav-link'>
									Contact
								</Link>
							</li>
						</ul>
					</div>
				</PageWrap>

				<PageWrap pageWrapClasses={'top-nav-mobile'}>
					<div className='top-nav-column'>
						<div
							className='top-nav-mobile-icon open'
							onClick={this.toggleMenu}
						></div>
						<Link to='/' className='top-nav-link'>
							<div className='top-nav-logo'>
								<img src={LogoBig} alt='Site logo' />
							</div>
						</Link>
					</div>
					<div
						className={`top-nav-mobile-sub-menu ${
							this.state.mobileMenu ? 'open' : 'closed'
						}`}
					>
						<div
							className={`top-nav-mobile-icon close`}
							onClick={this.toggleMenu}
						></div>
						<ul className='top-nav-mobile-list'>
							<li
								className='top-nav-mobile-link'
								onClick={this.toggleMenu}
							>
								<Link to='/' path='/'>
									HOME
								</Link>
							</li>
							<li
								className='top-nav-mobile-link'
								onClick={this.toggleMenu}
							>
								<Link to='/about' path='/about'>
									ABOUT
								</Link>
							</li>
							<li
								className='top-nav-mobile-link'
								onClick={this.toggleMenu}
							>
								<Link to='/gallery'>GALLERY</Link>
							</li>
							<li
								className='top-nav-mobile-link'
								onClick={this.toggleMenu}
							>
								<Link to='/pricing'>PRICING</Link>
							</li>
							<li
								className='top-nav-mobile-link'
								onClick={this.toggleMenu}
							>
								<Link to='/contact'>CONTACT</Link>
							</li>
						</ul>
					</div>
				</PageWrap>
			</div>
		);
	}
}

export default withRouter(TopNav);
