import React from 'react';
import PageWrap from '../PageWrap/PageWrap';
import Header from '../Header/Header';
import { Redirect } from 'react-router-dom';
import './Contact.css';

class MessageSuccess extends React.Component {
	state = {
		redirect: false,
	};
	componentDidMount = () => {
		this.timeout = setTimeout(
			() => this.setState({ redirect: true }),
			3500
		);
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to='/' />;
		} else {
			return (
				<div className='contact-success-container page-content'>
					<PageWrap pageWrapClasses={'contact-success'}>
						<Header page='contact-success' />
						<h3>
							Your message has been successfully sent. I will do
							my best to respond to you within 24 hours of
							receiving your message.
						</h3>
						<h3>
							Thank you and I look forward to working together
							with you.
						</h3>
					</PageWrap>
				</div>
			);
		}
	}
}

export default MessageSuccess;
