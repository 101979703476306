import React from 'react';
import './HomePage.css';

class HomePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div className='homepage-container page-content'>
				<div className='homepage-overlay'>
					<h2 className='title line-1'>Capture the moments</h2>
					<h2 className='title line-2'>Relive them for a lifetime</h2>
					<h2 className='title line-3'>Cherish them forever</h2>
				</div>
			</div>
		);
	}
}

export default HomePage;
