import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import TopNav from './components/TopNav/TopNav';
import ContentArea from './components/ContentArea/ContentArea';
import Footer from './components/Footer/Footer';
import './App.css';

library.add(fab, fas, far);

function App() {
	return (
		<BrowserRouter>
			<TopNav />
			<ContentArea />
			<Footer />
		</BrowserRouter>
	);
}

export default App;
