import React from 'react';
import HomePage from '../HomePage/HomePage';
import About from '../About/About';
import Gallery from '../Gallery/Gallery';
import Pricing from '../Pricing/Pricing';
import Contact from '../Contact/Contact';
import MessageSuccess from '../Contact/MessageSuccess';
import { Switch, Route } from 'react-router-dom';
import './ContentArea.css';

class ContentArea extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div className='content-area'>
				<Switch>
					<Route exact path='/' component={HomePage} />
					<Route exact path='/about' component={About} />
					<Route exact path='/gallery' component={Gallery} />
					<Route exact path='/pricing' component={Pricing} />
					<Route exact path='/contact' component={Contact} />
					<Route
						exact
						path='/contact/success'
						component={MessageSuccess}
					/>
				</Switch>
			</div>
		);
	}
}

export default ContentArea;
