import React from 'react';
import PageWrap from '../PageWrap/PageWrap';
import Header from '../Header/Header';
import './Pricing.css';

class Pricing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	importAll = (r) => {
		return r.keys().map(r);
	};

	getImageIndex = (imageArray, string) => {
		return imageArray.findIndex((element) => element.includes(string));
	};

	render() {
		const images = this.importAll(
			require.context('./images', false, /\.(png|jpe?g|svg)$/)
		);
		return (
			<div className='pricing-container page-content'>
				<PageWrap pageWrapClasses={'pricing'}>
					<Header page='pricing' />

					<h4 className='package-additional-info'>
						**Studio sessions are an option at an additional cost of
						$125, with each additional person being $25, additional
						images can be purchased at $25 each.
					</h4>

					{/* FAMILY PACKAGES START */}

					<div className='package-container'>
						<h3 className='package-header'>
							<span className='package-header-script'>
								Family
							</span>{' '}
							Packages
						</h3>
						<div className='package-info-row'>
							<div className='package-image-container'>
								<img
									src={
										images[
											this.getImageIndex(
												images,
												'family1'
											)
										]
									}
									alt='Family Package 1'
								/>
							</div>
							<div className='package-info-text'>
								<h3 className='package-info-title'>Mini Me</h3>
								<ul className='package-info-list'>
									<li className='package-info-list-item'>
										$150 + HST
									</li>
									<li className='package-info-list-item'>
										30 mins
									</li>
									<li className='package-info-list-item'>
										Outdoor or Studio
									</li>
									<li className='package-info-list-item'>
										5 digital images
									</li>
									<li className='package-info-list-item'>
										Personal Gallery
									</li>
									<li className='package-info-list-item'>
										Photo Release
									</li>
								</ul>
							</div>
						</div>

						<div className='package-info-row'>
							<div className='package-info-text left'>
								<h3 className='package-info-title'>
									Family Session
								</h3>
								<ul className='package-info-list'>
									<li className='package-info-list-item'>
										$300 + HST
									</li>
									<li className='package-info-list-item'>
										30 mins - 1 hour
									</li>
									<li className='package-info-list-item'>
										Outdoor or Studio
									</li>
									<li className='package-info-list-item'>
										10 digital images
									</li>
									<li className='package-info-list-item'>
										Personal Gallery
									</li>
									<li className='package-info-list-item'>
										Photo Release
									</li>
								</ul>
							</div>
							<div className='package-image-container'>
								<img
									src={
										images[
											this.getImageIndex(
												images,
												'family2'
											)
										]
									}
									alt='Family Package 2'
								/>
							</div>
						</div>

						<div className='package-info-row'>
							<div className='package-image-container'>
								<img
									src={
										images[
											this.getImageIndex(
												images,
												'family3'
											)
										]
									}
									alt='Family Package 3'
								/>
							</div>
							<div className='package-info-text'>
								<h3 className='package-info-title'>
									Full Extended Family Session
								</h3>
								<ul className='package-info-list'>
									<li className='package-info-list-item'>
										$500 + HST
									</li>
									<li className='package-info-list-item'>
										1 - 1.5 hours
									</li>
									<li className='package-info-list-item'>
										Outdoor or Studio
									</li>
									<li className='package-info-list-item'>
										Up to 15 people
									</li>
									<li className='package-info-list-item'>
										20 digital images
									</li>
									<li className='package-info-list-item'>
										Personal Gallery
									</li>
									<li className='package-info-list-item'>
										Photo Release
									</li>
								</ul>
							</div>
						</div>
					</div>
					{/* FAMILY PACKAGES END */}

					{/* GROW WITH ME PACKAGES START */}

					<div className='package-container'>
						<h3 className='package-header'>
							<span className='package-header-script'>
								Grow With Me
							</span>{' '}
							Packages
						</h3>
						<div className='package-info-row'>
							<div className='package-image-container'>
								<img
									src={
										images[
											this.getImageIndex(
												images,
												'growwithme1'
											)
										]
									}
									alt='Grow With Me Package 1'
								/>
							</div>
							<div className='package-info-text'>
								<h3 className='package-info-title'>
									Maternity Session
								</h3>
								<ul className='package-info-list'>
									<li className='package-info-list-item'>
										$200 + HST
									</li>
									<li className='package-info-list-item'>
										1 hour
									</li>
									<li className='package-info-list-item'>
										31-36 weeks
									</li>
									<li className='package-info-list-item'>
										Outdoor or on location
									</li>
									<li className='package-info-list-item'>
										Access to Photographer's Wardrobe
									</li>
									<li className='package-info-list-item'>
										10 digital images
									</li>
									<li className='package-info-list-item'>
										Personal Gallery
									</li>
									<li className='package-info-list-item'>
										Photo Release
									</li>
								</ul>
							</div>
						</div>

						<div className='package-info-row'>
							<div className='package-info-text left'>
								<h3 className='package-info-title'>
									Newborn Session
								</h3>
								<ul className='package-info-list'>
									<li className='package-info-list-item'>
										$400 + HST
									</li>
									<li className='package-info-list-item'>
										1-1.5 hours
									</li>
									<li className='package-info-list-item'>
										Outdoor or in studio
									</li>
									<li className='package-info-list-item'>
										10 digital images
									</li>
									<li className='package-info-list-item'>
										Personal Gallery
									</li>
									<li className='package-info-list-item'>
										Photo Release
									</li>
									<li className='package-info-list-item'>
										Newborn only
									</li>
								</ul>
								<h3
									className='package-info-title'
									style={{ marginTop: '10px' }}
								>
									Full Newborn Session
								</h3>
								<ul className='package-info-list'>
									<li className='package-info-list-item'>
										$500 + HST
									</li>
									<li className='package-info-list-item'>
										2-2.5 hours
									</li>
									<li className='package-info-list-item'>
										Family, siblings parents
									</li>
									<li className='package-info-list-item'>
										15 digital images
									</li>
								</ul>
							</div>
							<div className='package-image-container'>
								<img
									src={
										images[
											this.getImageIndex(
												images,
												'growwithme2'
											)
										]
									}
									alt='Grow With Me Package 2'
								/>
							</div>
						</div>

						<div className='package-info-row'>
							<div className='package-image-container'>
								<img
									src={
										images[
											this.getImageIndex(
												images,
												'growwithme3'
											)
										]
									}
									alt='Grow With Me Package 3'
								/>
							</div>
							<div className='package-info-text'>
								<h3 className='package-info-title'>
									Baby Milestones
								</h3>
								<ul className='package-info-list'>
									<li className='package-info-list-item'>
										$250 + HST
									</li>
									<li className='package-info-list-item'>
										30 mins
									</li>
									<li className='package-info-list-item'>
										3-4 months - Tummy Time
									</li>
									<li className='package-info-list-item'>
										6-9 months - Sitter Session
									</li>
									<li className='package-info-list-item'>
										11-12 months - 1st Birthday
									</li>
									<li className='package-info-list-item'>
										On location or in studio
									</li>
									<li className='package-info-list-item'>
										10 digital images
									</li>
									<li className='package-info-list-item'>
										Personal Gallery
									</li>
									<li className='package-info-list-item'>
										Photo Release
									</li>
								</ul>
							</div>
						</div>
					</div>
					{/* GROW WITH ME PACKAGES END */}

					{/* A LA CARTE START */}

					<div className='package-container'>
						<h3 className='package-header'>
							<span className='package-header-script'>
								A La Carte
							</span>{' '}
							Items
						</h3>
						<div className='package-info-row'>
							<div className='package-info-text'>
								<ul className='package-info-list'>
									<li className='package-info-list-item'>
										Albums starting from $250
									</li>
									<li className='package-info-list-item'>
										Prints starting from $75
									</li>
									<li className='package-info-list-item'>
										Canvas starting from $350
									</li>
								</ul>
							</div>
						</div>
					</div>
					{/* A LA CARTE END */}
				</PageWrap>
			</div>
		);
	}
}

export default Pricing;
